<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Google Adwords - Configuración</h4>
            <div class="small text-muted">Configurar la conexión para conectarnos al servicio</div>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer" no-body>
        <b-row>
          <b-col>      
            <b-tabs v-model="tabIndex" card>
              
              
              <b-tab title="Principal" :title-link-class="linkClass(0)">
                <b-card-text>
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-3">
                      Google Adwords
                      <small>
                        <b-badge variant="success" v-if="crud.form.active">ACTIVO</b-badge>
                        <b-badge variant="danger" v-else>INACTIVO</b-badge>
                      </small>                      
                    </h5>                      
                  </div>

                  
                  <b-row>
                    <b-col md="6" sm="12" xs="12">
                      <b-row>                                                
                        <b-col md="6">
                          <b-form-group label="Developer Token">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="crud.form.developerToken"                               
                                          placeholder="xxxxx">
                            </b-form-input>
                          </b-form-group>                          
                        </b-col>
                        <b-col md="6">
                          <b-form-group label="User Agent">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="crud.form.userAgent"                               
                                          placeholder="xxxxx">
                            </b-form-input>
                          </b-form-group>                          
                        </b-col>
                      </b-row>

                      <b-row>                                                
                        <b-col md="6">
                          <b-form-group label="Client ID">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="crud.form.clientID"                               
                                          placeholder="xxxxx">
                            </b-form-input>
                          </b-form-group>                          
                        </b-col>
                        <b-col md="6">
                          <b-form-group label="Client Secret">                          
                            <b-input-group class="w-100">                      
                              <b-form-input :type="password.typePassword"
                                            size="sm"
                                            autocomplete="off"
                                            v-model="crud.form.clientSecret"                               
                                            placeholder="*******">
                              </b-form-input>
                              <b-input-group-append>
                                <b-button variant="secondary" size="sm" @click="changeTypePassword()">
                                  <i class="icon material-icons">{{password.iconPassword}}</i>
                                </b-button>
                              </b-input-group-append>
                            </b-input-group>
                          </b-form-group>                      
                        </b-col>
                      </b-row>     
                      <b-row>                                                
                        <b-col md="12">
                          <b-form-group label="Redirect URI">
                            <b-form-input type="text"
                                          size="sm"
                                          disabled
                                          v-model="crud.form.redirectUri"                               
                                          placeholder="http o https">
                            </b-form-input>
                          </b-form-group>                          
                        </b-col>                                                                   
                        <b-col md="12">                                                                              
                          <label class="google-adwords-configuration-label">
                            Refresh Token
                          </label>
                          
                          <b-button @click="showGoogleAdwords" 
                                    size="sm" 
                                    class="pull-right mb-1" 
                                    variant="outline-dark"
                                    v-b-tooltip.hover 
                                    title="Actualizar el valor del refresh token">
                            <b-icon icon="arrow-repeat" />
                          </b-button>   

                          <b-form-textarea
                            v-model="crud.form.refreshToken"
                            rows="3"
                            max-rows="6"
                            size="sm"
                            disabled               
                            placeholder="Se genera de forma automática luego de conceder los permisos a la cuenta de Google"
                          ></b-form-textarea>                          
                        </b-col>
                      </b-row>  
                      <b-row class="mt-2">
                        <b-col md="6">                          
                          <b-button variant="dark" size="sm" @click="create()">Generar Enlace</b-button>                        
                        </b-col>
                        <b-col md="6">                          
                          <b-button variant="outline-success" class="pull-right" size="sm" @click="connect()" v-if="crud.status.connect && okConnect">
                            <i class="fa fa-google" />
                            Conectar
                          </b-button>                        
                        </b-col>                        
                      </b-row>
                      
                      <b-row>                                                
                        <b-col md="12">
                          <hr>
                        </b-col>
                      </b-row>
                      
                      <b-row class="mb-3">                                                
                        <b-col sm="8" md="8">
                          <label class="google-adwords-configuration-label" v-if="crud.form.labelCustomer">
                            Client Customer ID ({{this.crud.form.labelCustomer}})
                          </label>                          
                          <label class="google-adwords-configuration-label" v-else>
                            Client Customer ID 
                          </label>                                                    
                          
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="crud.form.clientCustomerID"                               
                                        placeholder="xxx-xxx-xxxx"
                                        autocomplete="off"
                                        :disabled="crud.form.active==true">
                          </b-form-input>                          
                        </b-col>
                        <b-col sm="4" md="4">
                          <b-button variant="outline-dark" 
                                    size="sm" 
                                    @click="changeTracking()"
                                    class="google-adwords-configuration-tracking-button"
                                    v-if="crud.form.active"
                                    v-b-tooltip.hover 
                                    title="Quitar la cuenta monitoreada">
                              <b-icon icon="x-circle"></b-icon>
                          </b-button>                          
                          <b-button variant="outline-danger" 
                                    size="sm" 
                                    @click="tracking()"
                                    class="google-adwords-configuration-tracking-button"
                                    v-if="!crud.form.active">
                              Monitorear Cuenta
                          </b-button>
                        </b-col>
                      </b-row>                        
                    </b-col>
                    <b-col md="6" sm="12" xs="12">                      
                      <b-card bg-variant="light" header="Implementación" text-variant="dark">
                        <b-card-text>
                          Para conectar con Google Adwords es necesario seguir los siguientes pasos.
                          <br><br>
                          1). Obtener un <b>Developer Token</b>, en este 
                          <a href="https://developers.google.com/google-ads/api/docs/first-call/dev-token" target="_blank"><b>link</b></a> explica cómo generarlo
                          <br>
                          2). Obtener el <b>Client ID</b> y el <b>Client Secret</b>, esto se genera desde Google Console, se debe crear un proyecto y luego generar las credenciales de conexión. 
                          En este <a href="https://developers.google.com/adwords/api/docs/guides/authentication" target="_blank"><b>link</b></a> explica los pasos a seguir.
                          <br>
                          3). Luego, debes configurar la <b>Redirect URI</b> (url de redirección) del OAuth2, en la Consola de Google solicita una URL cuando se configura. 
                          La URL que el sistema hace referencia en el formulario de configuración es la misma que debe cargarse en OAuth2.
                          <br>
                          4). Llegado a este paso, estamos listos para <b>Generar el Enlace</b> a Google Adwords a través de OAuth2. 
                          Una vez que se conceda los permisos, el sistema tendrá acceso a los datos de las cuentas de Adwords vinculadas.                          
                          <br>
                          5). Para finalizar, debemos cargar al sistema el <b>Client Customer ID</b>, que es el ID de identificación de una cuenta de Adwords.
                          Es necesario que la cuenta a vincular tenga permisos por la cuenta logueada en el paso 4.
                          Una vez vinculada con el sistema, podremos acceder a todos los datos.
                        </b-card-text>
                      </b-card>
                    </b-col>                    
                  </b-row>

                </b-card-text>
              </b-tab>              

            </b-tabs>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>
        </b-row>
      </b-card>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 

  export default {
    data: () => {
      return {      
        access: {
          module_id: Modules.GOOGLE_ADWORDS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'configuration',
          elements: {}
        }, 
        crud: { 
          form: {
            id: 1,         
            name: '',   
            developerToken: '',
            clientCustomerID: '',
            userAgent: '',
            clientID: '',
            clientSecret: '',
            refreshToken: '',
            redirectUri: '',
            connectionUri: '',
            active: false,
            labelCustomer: '',
          }, 
          status: {
            connect: false
          }            
        },  
        password: {
          typePassword: 'password',
          iconPassword: 'visibility',
        },
        tabIndex: 0,                    
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    }, 
    mounted () {
      this.showGoogleAdwords()        
    },
    computed: {
      okConnect() {
        if(this.crud.form.developerToken &&
          this.crud.form.userAgent &&
          this.crud.form.clientID &&
          this.crud.form.clientSecret &&
          this.crud.form.redirectUri) {
            return true
          }
      }
    },
    methods: {
      linkClass(idx) {
        if (this.tabIndex === idx) {
          return ['bg-primary', 'text-light']
        } else {
          return ['bg-light', 'text-info']
        }
      }, 

      showGoogleAdwords() {       
        let loader = this.$loading.show(); 
        var result = serviceAPI.mostrarGoogleAdwords(this.crud.form.id)

        result.then((response) => {
          loader.hide()
          var data = response.data
          
          this.crud.form.name = data.name          
          this.crud.form.developerToken = data.developerToken
          this.crud.form.clientCustomerID = data.clientCustomerID
          this.crud.form.userAgent = data.userAgent
          this.crud.form.clientID = data.clientID
          this.crud.form.clientSecret = data.clientSecret
          this.crud.form.refreshToken = data.refreshToken
          this.crud.form.redirectUri = data.redirectUri
          this.crud.form.active = data.active          
          this.crud.form.labelCustomer = data.labelCustomer  
        }) 
        .catch(error => {         
          loader.hide() 
          this.$awn.alert(Error.showError(error));
        }) 
      },
      create() {
        this.$bvModal.msgBoxConfirm('¿Se creará el enlace para conectar con Google Adwords?', {
          title: 'Google Adwords',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GUARDAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {            
            let loader = this.$loading.show();
            var result = serviceAPI.editarGoogleAdwords(this.crud.form);

            result.then((response) => {          
              loader.hide()          
              this.showGoogleAdwords()
              this.$awn.success("Datos del Google Adwords guardados con éxito");

              this.crud.status.connect = true
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            }) 
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      }, 
      connect() {  
        if(this.okConnect) {
          if(!this.refreshToken) {            
            let loader = this.$loading.show();
            var result = serviceAPI.getConnectionUri({
              google_adwords_id: this.crud.form.id 
            });

            result.then((response) => {          
              loader.hide()          
              
              window.open(response.data, '_blank')  

              this.showGoogleAdwords()            
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            }) 
          } else {
            this.$awn.alert("El Refresh Token ya se encuentra generado");  
          }
        } else {
          this.$awn.alert("Faltan configurar algunos datos para la conexión");  
        }
      },
      tracking() {                        
        if (!this.crud.form.clientCustomerID) {          
          this.$awn.alert("La identificación de la cuenta está vacia.")          
          return false
        }

        this.$bvModal.msgBoxConfirm('¿Monitorear la cuenta ' + this.crud.form.clientCustomerID + '?', {
          title: 'Google Adwords',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'MONITOREAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.editarClientCustomerIDGoogleAdwords(this.crud.form)

            result.then((response) => {                        
              loader.hide()

              this.crud.form.labelCustomer = response.data

              this.showGoogleAdwords()
              this.$awn.success('Conexión en funcionamiento!!')
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error))
            });
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },  
      changeTracking() {
        this.$bvModal.msgBoxConfirm('Quitar la cuenta ' + this.crud.form.clientCustomerID + '?', {
          title: 'Google Adwords',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'QUITAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.cambiarClientCustomerIDGoogleAdwords(this.crud.form)

            result.then((response) => {                        
              loader.hide()
              this.showGoogleAdwords()
              this.$awn.success('Cuenta liberada')
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error))
            });
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },    
      changeTypePassword() {
        if (this.password.typePassword === 'password') {
          this.password.typePassword = 'text'
          this.password.iconPassword = 'visibility_off'
        } else {
          this.password.typePassword = 'password'
          this.password.iconPassword  = 'visibility'
        }
      },          
    } 
  }
</script>
<style>
  .google-adwords-configuration-tracking-button {
    margin-top: 30px;
  }
  .google-adwords-configuration-label {
    font-weight: bold;
  }
</style>